import "../styles/index.scss";

(function () {
    let timeLeft = 30;
    setInterval(function () {
        if (timeLeft < 10) {
            document.getElementById("countdown").style.left = "182px";
        }
        if (timeLeft < 1) {
            document.location.href = "https://www.katsubet.com/";
        } else {
            document.getElementById("countdown").innerHTML = timeLeft;
        }
        timeLeft -= 1;
    }, 1000);
})();
